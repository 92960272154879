var keydown_timeout;
var keydown_xhr;
var uploadedInitiated = false;

function initContactForm() {

    $('.contact-form').validate({
        submitHandler: function() {
            var data = $('.contact-form').serialize();
            var object = {
                'success': function (response){
                    $('.contact-form .form-response').removeClass('success');
                    $('.contact-form .form-response').removeClass('error');
                    $('.contact-form .form-response').removeClass('show');
                    if(response['status']=='ok'){
                        $('.contact-form .form-response').addClass('success').addClass('show').html(response['message']);
                    }else{
                        $('.contact-form .form-response').addClass('error').addClass('show').html(response['error']);
                    }
                },
                'data' : data
            };
            $.ajax(object);
            return false;
        },
        errorPlacement: function(error, element) {
            // error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            /*$(element).parent().find('.form-control-feedback').remove();
             $(element).closest('.form-group').append('<span class=" form-control-feedback"><i class="fa fa-exclamation-triangle"></i></span>');
             $(element).parent().addClass('has-error has-feedback');*/
        },
        unhighlight: function (element, errorClass, validClass) {
            /*$(element).parent().find('.form-control-feedback').remove();
             $(element).parent().removeClass('has-error has-feedback');*/
        }
    });
}



function initMorphsearch(){
	var morphSearch = $('#morphsearch'),
		input = $('.search-button'),
		ctrlClose = $('span.morphsearch-close'),
		isOpen = isAnimating = false,

		toggleSearch = function (evt) {

			var offsets = morphsearch.getBoundingClientRect();
			if (isOpen) {
				morphSearch.removeClass('open');
				$('body').removeClass('search-open');
				$('.morphsearch-content .search-results').empty();
				$('.morphsearch-input').val('');
				//classie.remove( morphSearch, 'open' );

				// trick to hide input text once the search overlay closes
				// todo: hardcoded times, should be done after transition ends

				if (input.value !== '') {
					setTimeout(function () {
						morphSearch.addClass('hideInput');
						setTimeout(function () {
							morphSearch.removeClass('hideInput');
							input.value = '';
						}, 300);
					}, 500);
				}

				input.blur();
			}
			else {
				$('body').addClass('search-open');
				$('body.menu-open').removeClass('menu-open');
				morphSearch.addClass('open');
			}
			isOpen = !isOpen;
			return false;
		};

	input.on('click', toggleSearch);
	ctrlClose.on('click', toggleSearch);

	$(document).on('keydown', function (ev) {
		var keyCode = ev.keyCode || ev.which;
		if (keyCode === 27 && isOpen) {
			toggleSearch(ev);
		}
	});	
}

function getHash() {
    var hash = window.location.hash;
    return hash.substring(2); // remove #!
}


function confirm_overwrite(message, callback) {
    var c = confirm(message);
    if (c) {
        callback();
    }
}

function confirmation(callback, id, message) {
    var c = confirm(message);
    if (c) {
        callback(id);
    }
}

function scroll2element(el) {
    var position = $(el).offset();
    var $padding = $body.css('paddingTop').replace('px','');
    var y = position.top - 200;
    $('html, body').stop().animate({scrollTop: y}, 'slow');
}

function setUpSurveyProgress(step,current_step) {
    if(step == 5 && $('input[name="cv_file"]').length == 0){
        var container = $('#upload-container').closest('.form-group');
        container.find('.form-control-feedback').remove();
        container.append('<span class=" form-control-feedback"><i class="fa fa-times"></i></span>');
        container.addClass('has-error has-feedback');
        return false;
    }

    var progress = $('.survey-progress');
    var progress_bar = $('.survey-progress-bar');
    $('.survey-steps .step').removeClass('current');

    if (step > current_step) {
        $('.survey-steps .step[data-step="' + (step) + '"]').addClass('active').addClass('current');
        $('.survey-steps .step[data-step="' + (step - 1) + '"]').removeClass('current').addClass('active');
    }else{
        $('.survey-steps .step[data-step="' + (step) + '"]').addClass('active').addClass('current');
        $('.survey-steps .step[data-step="' + (step + 1) + '"]').removeClass('current').removeClass('active');
    }
    var active = $('.survey-steps .step.active:first');
    var last_active = $('.survey-steps .step.active:last');
    var last = $('.survey-steps .step:last');
    var current = $('.survey-steps .current:last');

    progress.css('left', active.position().left + active.width() * 0.5);
    progress.css('right', $('.survey-steps').width() - (last.position().left + last.width() * 0.5));

    progress_bar.css('left', active.position().left + active.width() * 0.5);
    progress_bar.css('right', $('.survey-steps').width() - (last_active.position().left + last_active.width() * 0.5 ));
    progress_bar.show();
    $('form[name="submit-cv-form"] .step[data-step="'+step+'"]').removeClass('hidden');
    $('form[name="submit-cv-form"] .step[data-step!="'+step+'"]').addClass('hidden');

    if(step == 5){
        submitCV();
    }else if(step == 3 && !uploadedInitiated){
        initUploader();
        uploadedInitiated = true;
    }
    return true;
}

function submitCV(){
    $('form[name="submit-cv-form"] .text-success').hide();
    $.ajax({data:$('form[name="submit-cv-form"]').serialize(),dataType : 'html',
        error:function($e){
            $('form[name="submit-cv-form"] .previous-step').trigger('click');

            $('form[name="submit-cv-form"] .text-error .message').html($e.responseText);
            $('form[name="submit-cv-form"] .text-error').show();
            $('form[name="submit-cv-form"] .text-success').hide();
            setTimeout(function(){
                $('form[name="submit-cv-form"] .text-error').hide();
            },5000);
        },success: function (data) {
            $('form[name="submit-cv-form"] .text-error').hide();
            $('form[name="submit-cv-form"] .text-success').show();
        }
    });
}


function submit_cv_form(){

    var current_step = 1;

    $('input').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
    });

    setUpSurveyProgress(current_step,current_step);

    $('form[name="submit-cv-form"] .add-education').on('click',function() {
        $('.education-field.hidden:first').removeClass('hidden');
        if($('.education-field.hidden').length == 0){
            $(this).hide();
        }
        return false;
    });

    $('form[name="submit-cv-form"] .add-previous-employment').on('click',function() {
        $('.previous-employment.hidden:first').removeClass('hidden');
        if($('.previous-employment.hidden').length == 0){
            $(this).hide();
        }
        return false;
    });
	
	 $('form[name="submit-cv-form"] .add-membership').on('click',function() {
        $('.membership-field.hidden:first').removeClass('hidden');
        if($('.membership-field.hidden').length == 0){
            $(this).hide();
        }
        return false;
    });
	
	
	$('form[name="submit-cv-form"] .yes-list-previous-employment input').on('ifChecked', function(event){
	   $('.cv_previous_dl_position').parent().removeClass('hidden');
	   $('.cv_prev_dl_date').removeClass('hidden');
	});
	
	$('form[name="submit-cv-form"] .no-list-previous-employment input').on('ifChecked', function(event){
	  $('.cv_previous_dl_position').parent().addClass('hidden');
	  $('.cv_prev_dl_date').addClass('hidden');
		
	});
	
	
	$('form[name="submit-cv-form"] .yes-list-cv_dl_employer_relation input').on('ifChecked', function(event){
	   $('.cv_relationship').removeClass('hidden');
	});
	
	$('form[name="submit-cv-form"] .no-list-cv_dl_employer_relation input').on('ifChecked', function(event){
	  $('.cv_relationship').addClass('hidden');
		
	});
	

    $('form[name="submit-cv-form"] .add-language').on('click',function() {
        $('.language-field.hidden:first').removeClass('hidden');
        if($('.language-field.hidden').length == 0){
            $(this).hide();
        }
        return false;
    });


    $('form[name="submit-cv-form"] .remove-education-field').on('click',function() {
        $(this).closest('.education-field').addClass('hidden');
        return false;
    });

    $('form[name="submit-cv-form"] .remove-previous-employment-field').on('click',function() {
        $(this).closest('.previous-employment').addClass('hidden');
        return false;
    });

    $('form[name="submit-cv-form"] .remove-language-field').on('click',function() {
        $(this).closest('.language-field').addClass('hidden');
        return false;
    });

    $(document).on('click','form[name="submit-cv-form"] .previous-step',function() {
        current_step  = current_step - 1;
        $('form[name="submit-cv-form"]').data(current_step);
        setUpSurveyProgress(current_step,current_step + 1);
        scroll2element($('.survey-steps-container'));
    });

    $(document).on('click','form[name="submit-cv-form"] .next-step',function() {
        if (v.form()) {

           // $('form[name="submit-cv-form"]').data(current_step);
            if(setUpSurveyProgress(current_step+1,current_step )){
                current_step  = current_step + 1;
            }
            scroll2element($('.survey-steps'));
        }else{
            scroll2element($('.form-group.has-error:first'));
        }
    });

    $.validator.addMethod("pageRequired", function(value, element) {
        var $element = $(element);

        function match(index) {
            return current_step == index && $(element).parents('.step[data-step="' + (current_step) +'"]').length;
        }
        if (match(0) || match(1) || match(2)) {
            if($(element).attr('name') == 'cv_army' && $('select[name=\'cv_sex\']').val() == 2){
                return true;
            }else if($(element).attr('name') == 'cv_email'){
                return checkEmail($(element).val());
            }
            return !this.optional(element);
        }
        return "dependency-mismatch";
    }, $.validator.messages.required);

    var v =  $('form[name="submit-cv-form"]').validate({

        errorPlacement: function(error, element) {
           // error.hide();
        },

        highlight: function (element, errorClass, validClass) {
            var container = $(element).closest('.form-group');
            container.find('.form-control-feedback').remove();
            container.append('<span class=" form-control-feedback"><i class="fa fa-times"></i></span>');
            container.addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            var container = $(element).closest('.form-group');
            container.find('.form-control-feedback').remove();
            container.removeClass('has-error has-feedback');
        },

        submitHandler: function() {
            alert("Submitted, thanks!");
        }
    });

}

function form_submit_response(target, type, message, r) {
    var redirect = r || false;
    var notification = new NotificationFx({
        message : message,
        layout : 'bar',
        effect : 'slidetop',
        type : type // notice, warning, error or success
    });

    // show the notification
    notification.show();

    if (redirect) {
        setTimeout(function () {
            window.location.reload();
        }, 2000);
    }
}

$(function(){

    $.validator.setDefaults({
        debug: false,
        errorPlacement: function(error, element) {
            error.hide();
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
            $(element).parent().append('<span class=" form-control-feedback"><i class="fa fa-times"></i></span>');
            $(element).parent().addClass('has-error has-feedback');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().find('.form-control-feedback').remove();
            $(element).parent().removeClass('has-error has-feedback');
        }
    });
});


function limitText(limitField, limitCount, limitNum) {
    if (limitField.value.length > limitNum) {
        limitField.value = limitField.value.substring(0, limitNum);
    } else {
        limitCount.html(limitNum - limitField.value.length);
    }
}

function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
function checkEmail(emailAddress) {
    var reValidEmail = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");

    return reValidEmail.test(emailAddress);
}